<template>
  <p-container>
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>

    <template slot="toolbar">
      <a-button type="primary">划分专家组</a-button>
      <a-button type="primary">查看打分情况</a-button>
      <a-button type="primary">Excel导出</a-button>
      <a-button type="primary">统计分析</a-button>
      <a-button type="primary">导出评价结果</a-button>
    </template>

    <template slot="searchbar">
      <a-form layout="inline">
        <a-form-item label="法律状态">
          <a-input placeholder="法律状态" style="width: 120px" />
        </a-form-item>
        <a-form-item label="所属单位">
          <a-select placeholder="所属单位" style="width: 120px">
          </a-select>
        </a-form-item>
        <a-form-item label="维持建议">
          <a-select placeholder="维持建议" style="width: 120px">
          </a-select>
        </a-form-item>
        <a-form-item label="打分状态">
          <a-select placeholder="打分状态" style="width: 120px">
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-select style="width: 120px">
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-input style="width: 120px" />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" html-type="submit">
            <a-icon type="search" />检索
          </a-button>
        </a-form-item>
      </a-form>
    </template>

    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
    ></a-table>
  </p-container>
</template>

<script>
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const fmTreeData = [
  {
    title: "所有评审专家组",
    key: "0-0",
    children: [
      {
        title: "未分组",
        key: "0-0-0"
      },
      {
        title: "发电设备",
        key: "0-0-1"
      },
      {
        title: "输电设备及高压",
        key: "0-0-2"
      },
      {
        title: "配电设备",
        key: "0-0-3"
      },
      {
        title: "用电设备",
        key: "0-0-4"
      },
    ],
  },
];
const treeData = [
  {
    title: "所有单位",
    key: "0-0",
    children: [
      {
        title: "公司总部",
        key: "0-0-0"
      },
      {
        title: "华北分部",
        key: "0-0-1"
      },
      {
        title: "华东分部",
        key: "0-0-2"
      },
      {
        title: "华中分部",
        key: "0-0-3"
      },
      {
        title: "东北分部",
        key: "0-0-4"
      },
    ],
  },
];
const columns = [
  {
    title: "专利名称",
    dataIndex: "name"
  },
  {
    title: "申请号",
    dataIndex: "age",
  },
  {
    title: "申请人",
    dataIndex: "address",
  },
];
export default {
  data() {
    return {
      treeData: [],
      rowSelection,
      dataSource: [
        {
          key: "0",
          name: "养生型合成石及其制品",
          age: "201210499081.5",
          address: "中国电力科学研究院",
        },
      ],
      columns,
    };
  },
  computed: {
      type(){
          return this.$route.name.substr(this.$route.name.lastIndexOf("_")+1);
      },
      showLeft(){
        return this.type != 'all'
      }
  },
  watch: {
      $route(){
          this.initPage();
      }
  },
  created(){
      this.initPage()
  },
    methods: {
        initPage(){
            switch(this.type){
                case 'fm':
                    this.treeData = fmTreeData;
                    break;
                default:
                    this.treeData = treeData;
            }
        }
    }
};
</script>

<style>
</style>